import React from "react";
import EventRow from '../../Events/Components/EventRow';
import VenueRow from '../../Venues/Components/VenueRow';

export default function HomeScreen() {
    return <div>
        <EventRow/>
        <VenueRow/>
        <VenueRow/>
        <VenueRow/>
        <VenueRow/>
        <EventRow/>
        </div>
        }