import React, { useState } from 'react';

const TabBar = ({ items }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  return (
    <div className="tab-bar">
      {items.map((item, index) => (
        <a href={item.link} key={index} className={selectedTab === index ? 'tab selected' : 'tab'} onClick={() => handleTabClick(index)}>
          <div className="tab-icon">
            <img src={item.icon} alt={item.label} />
          </div>
          <div className="tab-label">{item.label}</div>
        </a>
      ))}
    </div>
  );
};

export default TabBar;
