import React from "react";

export default function EventCard(props) {
    return <div className="eventCard">
        <img src={props.imgURL} alt="venue"/>
        {props.displayMessage && props.displayMessage.trim() !== "" && (
        <div className="eventCardDisplayBox">
          <span className="EventCardDisplayMessage">{props.displayMessage}</span>
        </div>
      )}
    </div>
}