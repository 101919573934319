import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TabBar from './Navigation/TabBar/TabBar';
import BookingMainNavigation from './Navigation/BookingNavigation/BookingMainNavigation';
import HomeScreen from './Navigation/HomeNavigation/HomeScreen';
import EmbeddedPage from './Helpers/Components/EmbeddedWebPage';

const items = [
  { label: "Home", icon: "home", link: "/" },
  { label: "Bookings", icon: "search", link: "/bookings" },
  { label: "Profile", icon: "person", link: "/seven" },
];

function App() {
  return (
        <div>
          <BrowserRouter>
          <Routes>
          <Route path='/' element={<HomeScreen/>} />
          <Route path='/bookings' element={<BookingMainNavigation/>} />
          <Route path='/seven' element={<EmbeddedPage src="https://www.sevenrooms.com/reservations/gaiadubai"/>} />
          <Route path='/bookings/gaia' element={<EmbeddedPage src="https://www.sevenrooms.com/reservations/gaiadubai"/>} />
          <Route path='/bookings/blu' element={<EmbeddedPage src="https://www.sevenrooms.com/reservations/bludubai"/>} />
          <Route path='/bookings/penthouse' element={<EmbeddedPage src="https://www.sevenrooms.com/reservations/thepenthouse"/>} />
          <Route path='/bookings/bagatelle' element={<EmbeddedPage src="https://www.sevenrooms.com/reservations/bagatelledubai"/>} />
          </Routes>
          </BrowserRouter>
        <TabBar items={items} />
        </div>
  );
}

export default App;
