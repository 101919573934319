import React from "react";

export default function VenueCard(props) {
    return <div className="venueCard">
        <img src={props.imgURL} alt="venue"/>
        <div className="venueCardText">
        <p className="venueName">{props.venueName}</p>
        <p className="venueLocatedIn">{props.locatedIn}</p>
        </div>
    </div>
}