import React from "react";
import VenueCard from "./VenueCard";

export default function EventRow(props) {
    return<div className="your-component-container">
          <div className="your-component-header">
            <h2 className="your-component-header-title">All Venues</h2>
          </div>
          <div className="your-component-body">
            <div className="your-component-events-container">
            <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Dream Dubai" locatedIn="Address Beach Resort JBR" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2F4E84C864-5696-45A2-9428-F00186575780?alt=media&token=4a3ce94d-670d-4038-94ad-b157a00ef6d7"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <VenueCard venueName="Maiden Shanghai" locatedIn="Five Palm Jumeirah" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
            </div>
          </div>
        </div>
}