import React from "react";
import EventCard from "./EventCard";

export default function EventRow(props) {
    return<div className="your-component-container">
          <div className="eventRowHeader">
            <h2 className="eventRowTitle">All Events</h2>
          </div>
          <div className="your-component-body">
            <div className="your-component-events-container">
            <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
              <EventCard displayMessage="Maiden Shanghai" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
        <EventCard imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
              <div className="your-component-event-gap"></div>
              <div className="your-component-event-link">
              <EventCard displayMessage="Maiden Shanghai" imgURL="https://firebasestorage.googleapis.com:443/v0/b/crwd-8bc95.appspot.com/o/backgroundImages%2FAC922EA4-ADFE-4EAA-8D54-FE8F90C254EB?alt=media&token=7d36bc4f-4162-4c33-8477-b743881cc900"/>
              </div>
            </div>
          </div>
        </div>
}