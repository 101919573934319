import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EmbeddedPage from '../../Helpers/Components/EmbeddedWebPage';

export default function BookingMainNavigation() {
    return <div>
        <h4>Bookings</h4>
        <a href="/bookings/gaia">Gaia</a>
        <a href="/bookings/blu">Blu</a>
        <a href="/bookings/penthouse">Penthouse</a>
        <a href="/bookings/bagatelle">Bagatelle</a>
        </div>
        }